@import url("https://fonts.googleapis.com/css?family=Nunito");
@import "~bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.min.css";
@import "~jquery-datetimepicker/jquery.datetimepicker.css";
.select2-container {
  box-sizing: border-box;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;
}

.select2-container .select2-selection--single {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 28px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--single .select2-selection__rendered {
  display: block;
  padding-left: 8px;
  padding-right: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.select2-container .select2-selection--single .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.select2-container[dir=rtl] .select2-selection--single .select2-selection__rendered {
  padding-right: 8px;
  padding-left: 20px;
}

.select2-container .select2-selection--multiple {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  min-height: 32px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-container .select2-selection--multiple .select2-selection__rendered {
  display: inline;
  list-style: none;
  padding: 0;
}

.select2-container .select2-selection--multiple .select2-selection__clear {
  background-color: transparent;
  border: none;
  font-size: 1em;
}

.select2-container .select2-search--inline .select2-search__field {
  box-sizing: border-box;
  border: none;
  font-size: 100%;
  margin-top: 5px;
  margin-left: 5px;
  padding: 0;
  max-width: 100%;
  resize: none;
  height: 18px;
  vertical-align: bottom;
  font-family: sans-serif;
  overflow: hidden;
  word-break: keep-all;
}

.select2-container .select2-search--inline .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-dropdown {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: -100000px;
  width: 100%;
  z-index: 1051;
}

.select2-results {
  display: block;
}

.select2-results__options {
  list-style: none;
  margin: 0;
  padding: 0;
}

.select2-results__option {
  padding: 6px;
  user-select: none;
  -webkit-user-select: none;
}

.select2-results__option--selectable {
  cursor: pointer;
}

.select2-container--open .select2-dropdown {
  left: 0;
}

.select2-container--open .select2-dropdown--above {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-search--dropdown {
  display: block;
  padding: 4px;
}

.select2-search--dropdown .select2-search__field {
  padding: 4px;
  width: 100%;
  box-sizing: border-box;
}

.select2-search--dropdown .select2-search__field::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

.select2-search--dropdown.select2-search--hide {
  display: none;
}

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto;
  opacity: 0;
  z-index: 99;
  background-color: #fff;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}

.select2-container--default .select2-selection--single {
  background-color: #fff;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--default .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
  padding-right: 0px;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--default[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}

.select2-container--default.select2-container--disabled .select2-selection--single {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}

.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--default .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  padding-bottom: 5px;
  padding-right: 5px;
  position: relative;
}

.select2-container--default .select2-selection--multiple.select2-selection--clearable {
  padding-right: 25px;
}

.select2-container--default .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  font-weight: bold;
  height: 20px;
  margin-right: 10px;
  margin-top: 5px;
  position: absolute;
  right: 0;
  padding: 1px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  box-sizing: border-box;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
  padding-left: 20px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-right: 1px solid #aaa;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #999;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
  position: absolute;
  left: 0;
  top: 0;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove:hover, .select2-container--default .select2-selection--multiple .select2-selection__choice__remove:focus {
  background-color: #f1f1f1;
  color: #333;
  outline: none;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-left: 1px solid #aaa;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--default[dir=rtl] .select2-selection--multiple .select2-selection__clear {
  float: left;
  margin-left: 10px;
  margin-right: auto;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
  background-color: #eee;
  cursor: default;
}

.select2-container--default.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single, .select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single, .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
}

.select2-container--default .select2-search--inline .select2-search__field {
  background: transparent;
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-appearance: textfield;
}

.select2-container--default .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--default .select2-results__option .select2-results__option {
  padding-left: 1em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__group {
  padding-left: 0;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -1em;
  padding-left: 2em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -2em;
  padding-left: 3em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -3em;
  padding-left: 4em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -4em;
  padding-left: 5em;
}

.select2-container--default .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option .select2-results__option {
  margin-left: -5em;
  padding-left: 6em;
}

.select2-container--default .select2-results__option--group {
  padding: 0;
}

.select2-container--default .select2-results__option--disabled {
  color: #999;
}

.select2-container--default .select2-results__option--selected {
  background-color: #ddd;
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #5897fb;
  color: white;
}

.select2-container--default .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic .select2-selection--single {
  background-color: #f7f7f7;
  border: 1px solid #aaa;
  border-radius: 4px;
  outline: 0;
  background-image: -webkit-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: -o-linear-gradient(top, white 50%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, white 50%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic .select2-selection--single:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--single .select2-selection__rendered {
  color: #444;
  line-height: 28px;
}

.select2-container--classic .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  height: 26px;
  margin-right: 20px;
}

.select2-container--classic .select2-selection--single .select2-selection__placeholder {
  color: #999;
}

.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background-color: #ddd;
  border: none;
  border-left: 1px solid #aaa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, #cccccc 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, #cccccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFCCCCCC", GradientType=0);
}

.select2-container--classic .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: -2px;
  position: absolute;
  top: 50%;
  width: 0;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}

.select2-container--classic[dir=rtl] .select2-selection--single .select2-selection__arrow {
  border: none;
  border-right: 1px solid #aaa;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  left: 1px;
  right: auto;
}

.select2-container--classic.select2-container--open .select2-selection--single {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow {
  background: transparent;
  border: none;
}

.select2-container--classic.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--single {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-image: -webkit-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: -o-linear-gradient(top, white 0%, #eeeeee 50%);
  background-image: linear-gradient(to bottom, white 0%, #eeeeee 50%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFFFFFFF", endColorstr="#FFEEEEEE", GradientType=0);
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--single {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-image: -webkit-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: -o-linear-gradient(top, #eeeeee 50%, white 100%);
  background-image: linear-gradient(to bottom, #eeeeee 50%, white 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#FFEEEEEE", endColorstr="#FFFFFFFF", GradientType=0);
}

.select2-container--classic .select2-selection--multiple {
  background-color: white;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: text;
  outline: 0;
  padding-bottom: 5px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple:focus {
  border: 1px solid #5897fb;
}

.select2-container--classic .select2-selection--multiple .select2-selection__clear {
  display: none;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice {
  background-color: #e4e4e4;
  border: 1px solid #aaa;
  border-radius: 4px;
  display: inline-block;
  margin-left: 5px;
  margin-top: 5px;
  padding: 0;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__display {
  cursor: default;
  padding-left: 2px;
  padding-right: 5px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove {
  background-color: transparent;
  border: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  color: #888;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
  padding: 0 4px;
}

.select2-container--classic .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #555;
  outline: none;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 5px;
  padding-right: 2px;
}

.select2-container--classic[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.select2-container--classic.select2-container--open .select2-selection--multiple {
  border: 1px solid #5897fb;
}

.select2-container--classic.select2-container--open.select2-container--above .select2-selection--multiple {
  border-top: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select2-container--classic.select2-container--open.select2-container--below .select2-selection--multiple {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--classic .select2-search--dropdown .select2-search__field {
  border: 1px solid #aaa;
  outline: 0;
}

.select2-container--classic .select2-search--inline .select2-search__field {
  outline: 0;
  box-shadow: none;
}

.select2-container--classic .select2-dropdown {
  background-color: white;
  border: 1px solid transparent;
}

.select2-container--classic .select2-dropdown--above {
  border-bottom: none;
}

.select2-container--classic .select2-dropdown--below {
  border-top: none;
}

.select2-container--classic .select2-results > .select2-results__options {
  max-height: 200px;
  overflow-y: auto;
}

.select2-container--classic .select2-results__option--group {
  padding: 0;
}

.select2-container--classic .select2-results__option--disabled {
  color: grey;
}

.select2-container--classic .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #3875d7;
  color: white;
}

.select2-container--classic .select2-results__group {
  cursor: default;
  display: block;
  padding: 6px;
}

.select2-container--classic.select2-container--open .select2-dropdown {
  border-color: #5897fb;
}

.treeview .list-group-item {
  cursor: pointer;
}

.treeview span.indent {
  margin-left: 10px;
  margin-right: 10px;
}

.treeview span.icon {
  width: 12px;
  margin-right: 5px;
}

.treeview .node-disabled {
  color: silver;
  cursor: not-allowed;
}

.treeview .node-hidden {
  display: none;
}

th > a {
  color: black;
}

.select2-selection--multiple {
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  border-radius: 2px;
  cursor: text;
  min-height: 22px;
  overflow: auto !important;
}

.select2-selection.select2-selection--single {
  height: 38px;
  border: 1px solid #ced4da;
}
.select2-selection.select2-selection--single .select2-selection__rendered {
  line-height: 38px;
}
.select2-selection.select2-selection--single .select2-selection__arrow {
  height: 38px;
}

.bsk-btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.42857;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 0;
}

.bsk-btn-default {
  color: #333;
  background-color: #eee;
  border-color: #eee;
}

.bsk-btn-default:hover {
  color: #333;
  background-color: #d5d5d5;
  border-color: #cfcfcf;
}

.x-icon {
  height: 1em;
  width: 1em;
  top: 0.125em;
  position: relative;
}

.x-alt {
  color: #777;
}

.logo-src {
  margin: auto;
}

#downloadkey {
  color: #af2366;
}

.apexcharts-xaxistooltip {
  display: none !important;
}

.apexcharts-canvas {
  padding-top: 10px;
  height: 100% !important;
}

.treeview .node-disabled {
  background: #fff !important;
  color: silver !important;
}

.card-header {
  min-height: 3.5rem;
  height: auto;
}

.modal.show {
  overflow-y: auto;
  padding-left: 0 !important;
  padding-right: 0 !important;
  top: 100px !important;
}

.tab-content > .tab-pane:not(.active) {
  display: block;
  height: 0;
  overflow-y: hidden;
}

.card-list {
  margin-bottom: 0.75rem;
  border-radius: 0.5rem;
  padding: 10px;
  background: #6c757d;
}

.scrollbar-sidebar {
  overflow-y: auto;
}

.no-border {
  border-radius: 0;
  border: 0;
  box-shadow: none;
}

.no-border:active {
  border-width: 0 0 2px;
}

#calc-table input:disabled {
  border: none;
}

.form-group.has-error .form-control {
  border-color: #d92550;
  padding-right: 2.25rem;
  background-repeat: no-repeat;
  background-position: center right 0.5625rem;
  background-size: 1.125rem 1.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23d9534f' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
}

.timeline {
  list-style: none;
  padding: 0;
  text-align: center;
}

.timeline::after {
  display: block;
  clear: both;
  content: "";
}

.timeline-marker {
  clear: both;
  background: #fff;
}

.timeline-marker h1,
.timeline-marker h2,
.timeline-marker h3,
.timeline-marker h4,
.timeline-marker h5 {
  margin: 0 0 5px;
}

.timeline-marker:before {
  display: none;
}

.timeline-marker.timeline-marker-bottom h1,
.timeline-marker.timeline-marker-bottom h2,
.timeline-marker.timeline-marker-bottom h3,
.timeline-marker.timeline-marker-bottom h4,
.timeline-marker.timeline-marker-bottom h5 {
  margin: 5px 0 0;
}

.timeline-marker.timeline-marker-bottom:before {
  display: block;
}

.timeline-marker.timeline-marker-bottom:after {
  display: none;
}

.timeline-marker.timeline-marker-middle {
  margin-top: 20px;
  margin-bottom: 20px;
}

.timeline-breaker {
  background: #1b1b1b;
  color: #fff;
  font-weight: 600;
  border-radius: 2px;
  margin: 0 auto;
  text-align: center;
  padding: 0.6em;
  line-height: 1;
  display: block;
  width: 100%;
  max-width: 15em;
  clear: both;
}

.timeline-breaker::after {
  display: block;
  clear: both;
  content: "";
}

.timeline-breaker a {
  color: #fff;
}

.timeline-breaker a:hover {
  color: #eee;
}

.timeline-breaker:after,
.timeline-breaker:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 1px;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.timeline-breaker:before {
  border-top-color: rgba(0, 0, 0, 0.01);
  border-width: 11px;
  left: 50%;
  margin-left: -11px;
}

.timeline-breaker.timeline-breaker-bottom,
.timeline-breaker.timeline-breaker-middle {
  margin-top: 40px;
  margin-bottom: 20px;
  clear: both !important;
}

.timeline-breaker.timeline-breaker-bottom {
  margin-bottom: 0;
}

.timeline-breaker.timeline-breaker-bottom:after,
.timeline-breaker.timeline-breaker-bottom:before {
  top: -10px;
  border-top: none;
}

.timeline-breaker.timeline-breaker-bottom:before {
  border-bottom-color: rgba(0, 0, 0, 0.01);
}

.timeline-item.timeline-item-first {
  margin-top: 20px !important;
}

.timeline-item.timeline-item-last {
  margin-bottom: 20px !important;
}

.timeline-item {
  float: none;
  left: auto;
  right: auto;
  width: 100%;
  padding: 15px;
  margin: 60px auto 0;
  background: #f6f6f6;
  border-radius: 2px;
  position: relative;
  border: 1px solid #f2f2f2;
  border-bottom: 3px solid #55a79a;
  text-align: left;
}

.timeline-item::after {
  display: block;
  clear: both;
  content: "";
}

.timeline-item:after,
.timeline-item:before {
  top: -20px;
  right: 50%;
  left: 50%;
  position: absolute;
  pointer-events: none;
  display: block;
  font-size: 30px;
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  margin-top: 0;
  margin-left: -14px;
}

.timeline-item:after,
.timeline-item:before,
.timeline-stacked-down .timeline-item:before {
  display: inline-block;
  font-weight: 900;
  font: var(--fa-font-solid);
  font-style: normal;
  speak: none;
}

.timeline-item:before {
  content: "\f0d8";
  color: #f6f6f6;
}

.timeline-item:after {
  content: "\f140";
  top: -39px;
  background: #fff;
}

.timeline-item.highlight:after,
.timeline-item.marker-highlight:after,
.timeline-item.tag-featured:after {
  color: #65b1a5;
}

.timeline-item.overlap-push-large {
  margin-top: 120px;
}

.timeline-item.overlap-push-medium {
  margin-top: 60px;
}

.timeline-item.overlap-push-small {
  margin-top: 30px;
}

.timeline-stacked-down .timeline-item:first-child {
  margin-top: 40px;
}

.timeline-stacked-down .timeline-item:last-child {
  margin-bottom: 60px;
}

.timeline-stacked-down .timeline-item:after,
.timeline-stacked-down .timeline-item:before {
  bottom: -20px;
  top: auto;
}

.timeline-stacked-down .timeline-item:before {
  content: "\f0d7";
}

.timeline-stacked-down .timeline-item:after {
  bottom: -40px;
  top: auto;
}

.timeline-item-date {
  font-weight: 600;
  color: #666;
}

.timeline-item-title {
  margin-top: 0;
}

@media (min-width: 576px) {
  .timeline-breaker.timeline-breaker-bottom,
  .timeline-breaker.timeline-breaker-middle {
    top: 40px;
  }
  .timeline-item {
    float: left;
    width: 48%;
    padding: 15px;
    margin-top: 40px;
    right: 30px;
    margin-left: 2%;
    clear: left;
  }
  .timeline-item:after,
  .timeline-item:before {
    top: 10%;
    bottom: auto;
    right: -20px;
    left: auto;
    position: absolute;
    pointer-events: none;
    margin: 0;
    display: block;
    font-size: 30px;
    height: 30px;
    line-height: 30px;
    width: 30px;
    text-align: center;
  }
  .timeline-item.even:before,
  .timeline-item.right:before,
  .timeline-item:before {
    display: inline-block;
    font: var(--fa-font-solid);
    font-weight: 900;
    font-style: normal;
    speak: none;
  }
  .timeline-item:before {
    content: "\f0da";
  }
  .timeline-item:after {
    right: -46px;
  }
  .timeline-item.even,
  .timeline-item.right {
    float: right;
    clear: right;
    left: 30px;
    right: 0;
    margin-right: 2%;
    margin-left: 0;
    margin-top: 100px;
  }
  .timeline-item.even:after,
  .timeline-item.even:before,
  .timeline-item.right:after,
  .timeline-item.right:before {
    left: -20px;
    top: 10%;
  }
  .timeline-item.even:before,
  .timeline-item.right:before {
    content: "\f0d9";
  }
  .timeline-item.even:after,
  .timeline-item.right:after {
    left: -46px;
  }
  .timeline-item.overlap-off {
    margin-top: 0;
  }
  .timeline-item.overlap-pull-large {
    margin-top: -120px;
  }
  .timeline-item.overlap-pull-small {
    margin-top: -30px;
  }
  .timeline-stacked,
  .timeline.timeline-stacked {
    padding-left: 0;
    padding-right: 0;
  }
  .timeline-stacked .timeline-item,
  .timeline-stacked .timeline-item.even,
  .timeline-stacked .timeline-item.right,
  .timeline.timeline-stacked .timeline-item,
  .timeline.timeline-stacked .timeline-item.even,
  .timeline.timeline-stacked .timeline-item.right {
    float: none;
    left: auto;
    right: auto;
    width: 100%;
    padding: 15px;
    margin: 80px auto 0;
    background: #f6f6f6;
    border-radius: 2px;
    position: relative;
    border: 1px solid #f2f2f2;
    border-bottom: 3px solid #55a79a;
    text-align: left;
  }
  .timeline-stacked .timeline-item.even::after,
  .timeline-stacked .timeline-item.right::after,
  .timeline-stacked .timeline-item::after,
  .timeline.timeline-stacked .timeline-item.even::after,
  .timeline.timeline-stacked .timeline-item.right::after,
  .timeline.timeline-stacked .timeline-item::after {
    display: block;
    clear: both;
    content: "";
  }
  .timeline-stacked .timeline-item.even:after,
  .timeline-stacked .timeline-item.even:before,
  .timeline-stacked .timeline-item.right:after,
  .timeline-stacked .timeline-item.right:before,
  .timeline-stacked .timeline-item:after,
  .timeline-stacked .timeline-item:before,
  .timeline.timeline-stacked .timeline-item.even:after,
  .timeline.timeline-stacked .timeline-item.even:before,
  .timeline.timeline-stacked .timeline-item.right:after,
  .timeline.timeline-stacked .timeline-item.right:before,
  .timeline.timeline-stacked .timeline-item:after,
  .timeline.timeline-stacked .timeline-item:before {
    top: -20px;
    right: 50%;
    left: 50%;
    position: absolute;
    pointer-events: none;
    display: block;
    font-size: 30px;
    height: 30px;
    line-height: 30px;
    width: 30px;
    text-align: center;
    margin-top: 0;
    margin-left: -14px;
  }
  .timeline-stacked .timeline-item.even:before,
  .timeline-stacked .timeline-item.right:before,
  .timeline-stacked .timeline-item:before,
  .timeline.timeline-stacked .timeline-item.even:before,
  .timeline.timeline-stacked .timeline-item.right:before,
  .timeline.timeline-stacked .timeline-item:before {
    font: var(--fa-font-solid);
    font-weight: 900;
    font-style: normal;
    speak: none;
    display: inline-block;
    content: "\f0d8";
    color: #f6f6f6;
  }
  .timeline-stacked .timeline-item.even:after,
  .timeline-stacked .timeline-item.right:after,
  .timeline-stacked .timeline-item:after,
  .timeline.timeline-stacked .timeline-item.even:after,
  .timeline.timeline-stacked .timeline-item.right:after,
  .timeline.timeline-stacked .timeline-item:after {
    font: var(--fa-font-solid);
    font-weight: 900;
    font-style: normal;
    speak: none;
    display: inline-block;
    content: "\f140";
    top: -39px;
    background: #fff;
  }
  .timeline-stacked .timeline-item.even.highlight:after,
  .timeline-stacked .timeline-item.even.marker-highlight:after,
  .timeline-stacked .timeline-item.even.tag-featured:after,
  .timeline-stacked .timeline-item.highlight:after,
  .timeline-stacked .timeline-item.marker-highlight:after,
  .timeline-stacked .timeline-item.right.highlight:after,
  .timeline-stacked .timeline-item.right.marker-highlight:after,
  .timeline-stacked .timeline-item.right.tag-featured:after,
  .timeline-stacked .timeline-item.tag-featured:after,
  .timeline.timeline-stacked .timeline-item.even.highlight:after,
  .timeline.timeline-stacked .timeline-item.even.marker-highlight:after,
  .timeline.timeline-stacked .timeline-item.even.tag-featured:after,
  .timeline.timeline-stacked .timeline-item.highlight:after,
  .timeline.timeline-stacked .timeline-item.marker-highlight:after,
  .timeline.timeline-stacked .timeline-item.right.highlight:after,
  .timeline.timeline-stacked .timeline-item.right.marker-highlight:after,
  .timeline.timeline-stacked .timeline-item.right.tag-featured:after,
  .timeline.timeline-stacked .timeline-item.tag-featured:after {
    color: #65b1a5;
  }
  .timeline-stacked .timeline-item.even.overlap-push-large,
  .timeline-stacked .timeline-item.overlap-push-large,
  .timeline-stacked .timeline-item.right.overlap-push-large,
  .timeline.timeline-stacked .timeline-item.even.overlap-push-large,
  .timeline.timeline-stacked .timeline-item.overlap-push-large,
  .timeline.timeline-stacked .timeline-item.right.overlap-push-large {
    margin-top: 120px;
  }
  .timeline-stacked .timeline-item.even.overlap-push-medium,
  .timeline-stacked .timeline-item.overlap-push-medium,
  .timeline-stacked .timeline-item.right.overlap-push-medium,
  .timeline.timeline-stacked .timeline-item.even.overlap-push-medium,
  .timeline.timeline-stacked .timeline-item.overlap-push-medium,
  .timeline.timeline-stacked .timeline-item.right.overlap-push-medium {
    margin-top: 60px;
  }
  .timeline-stacked .timeline-item.even.overlap-push-small,
  .timeline-stacked .timeline-item.overlap-push-small,
  .timeline-stacked .timeline-item.right.overlap-push-small,
  .timeline.timeline-stacked .timeline-item.even.overlap-push-small,
  .timeline.timeline-stacked .timeline-item.overlap-push-small,
  .timeline.timeline-stacked .timeline-item.right.overlap-push-small {
    margin-top: 30px;
  }
  .timeline-stacked.timeline-stacked-down .timeline-item:first-child,
  .timeline.timeline-stacked.timeline-stacked-down .timeline-item:first-child {
    margin-top: 40px;
  }
  .timeline-stacked.timeline-stacked-down .timeline-item:last-child,
  .timeline.timeline-stacked.timeline-stacked-down .timeline-item:last-child {
    margin-bottom: 60px;
  }
  .timeline-stacked.timeline-stacked-down .timeline-item:after,
  .timeline-stacked.timeline-stacked-down .timeline-item:before,
  .timeline.timeline-stacked.timeline-stacked-down .timeline-item:after,
  .timeline.timeline-stacked.timeline-stacked-down .timeline-item:before {
    bottom: -20px;
    top: auto;
  }
  .timeline-stacked.timeline-stacked-down .timeline-item:before,
  .timeline.timeline-stacked.timeline-stacked-down .timeline-item:before {
    font: var(--fa-font-solid);
    font-weight: 900;
    font-style: normal;
    speak: none;
    display: inline-block;
    content: "\f0d7";
  }
  .timeline-stacked.timeline-stacked-down .timeline-item:after,
  .timeline.timeline-stacked.timeline-stacked-down .timeline-item:after {
    bottom: -40px;
    top: auto;
  }
  .timeline-stacked.timeline-stacked-down .timeline-item.even.last,
  .timeline-stacked.timeline-stacked-down .timeline-item.last,
  .timeline-stacked.timeline-stacked-down .timeline-item.right.last,
  .timeline.timeline-stacked.timeline-stacked-down .timeline-item.even.last,
  .timeline.timeline-stacked.timeline-stacked-down .timeline-item.last,
  .timeline.timeline-stacked.timeline-stacked-down .timeline-item.right.last {
    margin-bottom: 40px;
  }
  .timeline-stacked .timeline-breaker.timeline-breaker-bottom,
  .timeline-stacked .timeline-breaker.timeline-breaker-middle,
  .timeline.timeline-stacked .timeline-breaker.timeline-breaker-bottom,
  .timeline.timeline-stacked .timeline-breaker.timeline-breaker-middle {
    top: auto;
  }
}
.timeline-left,
.timeline.timeline-left {
  background-position: left top;
  margin-left: 20px;
  padding-bottom: 0;
  text-align: left;
}

.timeline-left .timeline-marker,
.timeline.timeline-left .timeline-marker {
  margin-left: -5px;
  margin-right: 0;
}

.timeline-left .timeline-marker:after,
.timeline-left .timeline-marker:before,
.timeline.timeline-left .timeline-marker:after,
.timeline.timeline-left .timeline-marker:before {
  margin-left: 0;
}

.timeline-left .timeline-breaker,
.timeline.timeline-left .timeline-breaker {
  margin-left: -20px;
  margin-top: 20px;
}

.timeline-left .timeline-breaker:after,
.timeline-left .timeline-breaker:before,
.timeline.timeline-left .timeline-breaker:after,
.timeline.timeline-left .timeline-breaker:before {
  left: 20px;
}

.timeline-left .timeline-item,
.timeline-left .timeline-item.even,
.timeline-left .timeline-item.right,
.timeline.timeline-left .timeline-item,
.timeline.timeline-left .timeline-item.even,
.timeline.timeline-left .timeline-item.right {
  float: none;
  clear: both;
  width: 92%;
  margin-left: 25px;
  margin-right: 0;
  margin-top: 40px;
  left: auto;
  right: auto;
}

.timeline-left .timeline-item.even:after,
.timeline-left .timeline-item.even:before,
.timeline-left .timeline-item.right:after,
.timeline-left .timeline-item.right:before,
.timeline-left .timeline-item:after,
.timeline-left .timeline-item:before,
.timeline.timeline-left .timeline-item.even:after,
.timeline.timeline-left .timeline-item.even:before,
.timeline.timeline-left .timeline-item.right:after,
.timeline.timeline-left .timeline-item.right:before,
.timeline.timeline-left .timeline-item:after,
.timeline.timeline-left .timeline-item:before {
  right: auto;
  left: -20px;
  margin-left: 0;
  top: 20px;
}

.timeline-left .timeline-item.even:before,
.timeline-left .timeline-item.right:before,
.timeline-left .timeline-item:before,
.timeline.timeline-left .timeline-item.even:before,
.timeline.timeline-left .timeline-item.right:before,
.timeline.timeline-left .timeline-item:before {
  font: var(--fa-font-solid);
  font-weight: 900;
  font-style: normal;
  speak: none;
  display: inline-block;
  content: "\f0d9";
}

.timeline-left .timeline-item.even:after,
.timeline-left .timeline-item.right:after,
.timeline-left .timeline-item:after,
.timeline.timeline-left .timeline-item.even:after,
.timeline.timeline-left .timeline-item.right:after,
.timeline.timeline-left .timeline-item:after {
  left: -40px;
}

.timeline-left .pagination,
.timeline.timeline-left .pagination {
  background: 0 0;
  padding-left: 1em;
  padding-right: 1em;
}

.timeline-left .timeline-breaker.timeline-breaker-bottom,
.timeline-left .timeline-breaker.timeline-breaker-middle,
.timeline.timeline-left .timeline-breaker.timeline-breaker-bottom,
.timeline.timeline-left .timeline-breaker.timeline-breaker-middle {
  top: auto;
  margin-bottom: 0;
}

.timeline-right,
.timeline.timeline-right {
  background-position: right top;
  margin-right: 20px;
  text-align: right;
}

.timeline-right .timeline-marker,
.timeline.timeline-right .timeline-marker {
  margin-left: 0;
  margin-right: -5px;
  float: right;
}

.timeline-right .timeline-breaker,
.timeline.timeline-right .timeline-breaker {
  margin-left: 0;
  margin-right: -20px;
  float: right;
}

.timeline-right .timeline-breaker:after,
.timeline-right .timeline-breaker:before,
.timeline.timeline-right .timeline-breaker:after,
.timeline.timeline-right .timeline-breaker:before {
  right: 12px;
  left: auto;
}

.timeline-right .timeline-item,
.timeline-right .timeline-item.even,
.timeline-right .timeline-item.right,
.timeline.timeline-right .timeline-item,
.timeline.timeline-right .timeline-item.even,
.timeline.timeline-right .timeline-item.right {
  float: right;
  clear: both;
  width: 92%;
  margin-left: 0;
  margin-right: 25px;
  margin-top: 40px;
  left: auto;
  right: 0;
}

.timeline-right .timeline-item.even:after,
.timeline-right .timeline-item.even:before,
.timeline-right .timeline-item.right:after,
.timeline-right .timeline-item.right:before,
.timeline-right .timeline-item:after,
.timeline-right .timeline-item:before,
.timeline.timeline-right .timeline-item.even:after,
.timeline.timeline-right .timeline-item.even:before,
.timeline.timeline-right .timeline-item.right:after,
.timeline.timeline-right .timeline-item.right:before,
.timeline.timeline-right .timeline-item:after,
.timeline.timeline-right .timeline-item:before {
  left: auto;
  right: -20px;
  margin-right: 0;
  top: 20px;
}

.timeline-right .timeline-item.even:before,
.timeline-right .timeline-item.right:before,
.timeline-right .timeline-item:before,
.timeline.timeline-right .timeline-item.even:before,
.timeline.timeline-right .timeline-item.right:before,
.timeline.timeline-right .timeline-item:before {
  font: var(--fa-font-solid);
  font-weight: 900;
  font-style: normal;
  speak: none;
  display: inline-block;
  content: "\f0da";
}

.timeline-right .timeline-item.even:after,
.timeline-right .timeline-item.right:after,
.timeline-right .timeline-item:after,
.timeline.timeline-right .timeline-item.even:after,
.timeline.timeline-right .timeline-item.right:after,
.timeline.timeline-right .timeline-item:after {
  right: -40px;
  left: auto;
}

.timeline-right .pagination,
.timeline.timeline-right .pagination {
  background: 0 0;
  padding-left: 1em;
  padding-right: 1em;
}

.timeline-right .timeline-breaker.timeline-breaker-bottom,
.timeline-right .timeline-breaker.timeline-breaker-middle,
.timeline.timeline-right .timeline-breaker.timeline-breaker-bottom,
.timeline.timeline-right .timeline-breaker.timeline-breaker-middle {
  top: auto;
  margin-bottom: 0;
}

.timeline-mini .timeline-item {
  padding: 0.5em !important;
  margin-top: 50px !important;
}

@media (min-width: 576px) {
  .timeline.timeline-left .timeline-item,
  .timeline.timeline-left .timeline-item.even,
  .timeline.timeline-left .timeline-item.right,
  .timeline.timeline-right .timeline-item,
  .timeline.timeline-right .timeline-item.even,
  .timeline.timeline-right .timeline-item.right {
    width: 96%;
  }
}
.carousel-timeline-nav .owl-nav div {
  margin-top: -2px;
}

.carousel-timeline-nav .owl-stage-outer {
  padding-bottom: 27px;
  padding-top: 15px;
  margin-bottom: 10px;
  position: relative;
}

.carousel-timeline-nav .owl-stage-outer:after {
  height: 2px;
  width: 500%;
  background: #292b2c;
  left: -200%;
  right: 0;
  top: auto;
  bottom: 12px;
  position: absolute;
  content: "";
  z-index: -1;
}

.carousel-timeline-nav.owl-nav-over .owl-stage-outer {
  margin-left: 30px;
  margin-right: 30px;
}

.carousel-timeline-nav.owl-nav-over-lg .owl-stage-outer {
  margin-left: 60px;
  margin-right: 60px;
}

.carousel-timeline-nav.owl-nav-over-lg .owl-nav div {
  margin-top: -20px !important;
}

.carousel-timeline-nav .owl-item {
  text-align: center;
}

.carousel-timeline-nav .owl-thumb {
  position: relative;
  display: block;
  opacity: 0.9;
  -webkit-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.carousel-timeline-nav .owl-thumb.active {
  opacity: 1;
}

.carousel-timeline-nav .owl-thumb:before {
  background: #fff;
  left: 0;
  right: 0;
  top: auto;
  position: absolute;
  content: "";
  margin-left: auto;
  margin-right: auto;
  height: 12px;
  width: 12px;
  border-radius: 12px;
  bottom: -20px;
  border: 2px solid #292b2c;
  z-index: 2;
  opacity: 1;
}

.carousel-timeline-nav .active.owl-thumb:before {
  background: #292b2c;
}

.list-timeline {
  margin: 0;
  padding: 5px 0;
  position: relative;
}

.list-timeline:before {
  width: 1px;
  background: #ccc;
  position: absolute;
  left: 6px;
  top: 0;
  bottom: 0;
  height: 100%;
  content: "";
}

.list-timeline .list-timeline-item {
  margin: 0;
  padding: 0;
  padding-left: 24px !important;
  position: relative;
}

.list-timeline .list-timeline-item:before {
  width: 12px;
  height: 12px;
  background: #fff;
  border: 2px solid #ccc;
  position: absolute;
  left: 0;
  top: 4px;
  content: "";
  border-radius: 100%;
  -webkit-transition: all 0.3 ease-in-out;
  transition: all 0.3 ease-in-out;
}

.list-timeline .list-timeline-item[data-toggle=collapse] {
  cursor: pointer;
}

.list-timeline .list-timeline-item.active:before,
.list-timeline .list-timeline-item.show:before {
  background: #ccc;
}

.list-timeline.list-timeline-light .list-timeline-item.active:before,
.list-timeline.list-timeline-light .list-timeline-item.show:before,
.list-timeline.list-timeline-light:before {
  background: #f8f9fa;
}

.list-timeline .list-timeline-item.list-timeline-item-marker-middle:before {
  top: 50%;
  margin-top: -6px;
}

.list-timeline.list-timeline-light .list-timeline-item:before {
  border-color: #f8f9fa;
}

.list-timeline.list-timeline-grey .list-timeline-item.active:before,
.list-timeline.list-timeline-grey .list-timeline-item.show:before,
.list-timeline.list-timeline-grey:before {
  background: #e9ecef;
}

.list-timeline.list-timeline-grey .list-timeline-item:before {
  border-color: #e9ecef;
}

.list-timeline.list-timeline-grey-dark .list-timeline-item.active:before,
.list-timeline.list-timeline-grey-dark .list-timeline-item.show:before,
.list-timeline.list-timeline-grey-dark:before {
  background: #495057;
}

.list-timeline.list-timeline-grey-dark .list-timeline-item:before {
  border-color: #495057;
}

.list-timeline.list-timeline-primary .list-timeline-item.active:before,
.list-timeline.list-timeline-primary .list-timeline-item.show:before,
.list-timeline.list-timeline-primary:before {
  background: #55a79a;
}

.list-timeline.list-timeline-primary .list-timeline-item:before {
  border-color: #55a79a;
}

.list-timeline.list-timeline-primary-dark .list-timeline-item.active:before,
.list-timeline.list-timeline-primary-dark .list-timeline-item.show:before,
.list-timeline.list-timeline-primary-dark:before {
  background: #33635c;
}

.list-timeline.list-timeline-primary-dark .list-timeline-item:before {
  border-color: #33635c;
}

.list-timeline.list-timeline-primary-faded .list-timeline-item.active:before,
.list-timeline.list-timeline-primary-faded .list-timeline-item.show:before,
.list-timeline.list-timeline-primary-faded:before {
  background: rgba(85, 167, 154, 0.3);
}

.list-timeline.list-timeline-primary-faded .list-timeline-item:before {
  border-color: rgba(85, 167, 154, 0.3);
}

.list-timeline.list-timeline-info .list-timeline-item.active:before,
.list-timeline.list-timeline-info .list-timeline-item.show:before,
.list-timeline.list-timeline-info:before {
  background: #17a2b8;
}

.list-timeline.list-timeline-info .list-timeline-item:before {
  border-color: #17a2b8;
}

.list-timeline.list-timeline-success .list-timeline-item.active:before,
.list-timeline.list-timeline-success .list-timeline-item.show:before,
.list-timeline.list-timeline-success:before {
  background: #28a745;
}

.list-timeline.list-timeline-success .list-timeline-item:before {
  border-color: #28a745;
}

.list-timeline.list-timeline-warning .list-timeline-item.active:before,
.list-timeline.list-timeline-warning .list-timeline-item.show:before,
.list-timeline.list-timeline-warning:before {
  background: #ffc107;
}

.list-timeline.list-timeline-warning .list-timeline-item:before {
  border-color: #ffc107;
}

.list-timeline.list-timeline-danger .list-timeline-item.active:before,
.list-timeline.list-timeline-danger .list-timeline-item.show:before,
.list-timeline.list-timeline-danger:before {
  background: #dc3545;
}

.list-timeline.list-timeline-danger .list-timeline-item:before {
  border-color: #dc3545;
}

.list-timeline.list-timeline-dark .list-timeline-item.active:before,
.list-timeline.list-timeline-dark .list-timeline-item.show:before,
.list-timeline.list-timeline-dark:before {
  background: #343a40;
}

.list-timeline.list-timeline-dark .list-timeline-item:before {
  border-color: #343a40;
}

.list-timeline.list-timeline-secondary .list-timeline-item.active:before,
.list-timeline.list-timeline-secondary .list-timeline-item.show:before,
.list-timeline.list-timeline-secondary:before {
  background: #6c757d;
}

.list-timeline.list-timeline-secondary .list-timeline-item:before {
  border-color: #6c757d;
}

.list-timeline.list-timeline-black .list-timeline-item.active:before,
.list-timeline.list-timeline-black .list-timeline-item.show:before,
.list-timeline.list-timeline-black:before {
  background: #000;
}

.list-timeline.list-timeline-black .list-timeline-item:before {
  border-color: #000;
}

.list-timeline.list-timeline-white .list-timeline-item.active:before,
.list-timeline.list-timeline-white .list-timeline-item.show:before,
.list-timeline.list-timeline-white:before {
  background: #fff;
}

.list-timeline.list-timeline-white .list-timeline-item:before {
  border-color: #fff;
}

.list-timeline.list-timeline-green .list-timeline-item.active:before,
.list-timeline.list-timeline-green .list-timeline-item.show:before,
.list-timeline.list-timeline-green:before {
  background: #55a79a;
}

.list-timeline.list-timeline-green .list-timeline-item:before {
  border-color: #55a79a;
}

.list-timeline.list-timeline-red .list-timeline-item.active:before,
.list-timeline.list-timeline-red .list-timeline-item.show:before,
.list-timeline.list-timeline-red:before {
  background: #be3e1d;
}

.list-timeline.list-timeline-red .list-timeline-item:before {
  border-color: #be3e1d;
}

.list-timeline.list-timeline-blue .list-timeline-item.active:before,
.list-timeline.list-timeline-blue .list-timeline-item.show:before,
.list-timeline.list-timeline-blue:before {
  background: #00adbb;
}

.list-timeline.list-timeline-blue .list-timeline-item:before {
  border-color: #00adbb;
}

.list-timeline.list-timeline-purple .list-timeline-item.active:before,
.list-timeline.list-timeline-purple .list-timeline-item.show:before,
.list-timeline.list-timeline-purple:before {
  background: #b771b0;
}

.list-timeline.list-timeline-purple .list-timeline-item:before {
  border-color: #b771b0;
}

.list-timeline.list-timeline-pink .list-timeline-item.active:before,
.list-timeline.list-timeline-pink .list-timeline-item.show:before,
.list-timeline.list-timeline-pink:before {
  background: #cc164d;
}

.list-timeline.list-timeline-pink .list-timeline-item:before {
  border-color: #cc164d;
}

.list-timeline.list-timeline-orange .list-timeline-item.active:before,
.list-timeline.list-timeline-orange .list-timeline-item.show:before,
.list-timeline.list-timeline-orange:before {
  background: #e67e22;
}

.list-timeline.list-timeline-orange .list-timeline-item:before {
  border-color: #e67e22;
}

.list-timeline.list-timeline-lime .list-timeline-item.active:before,
.list-timeline.list-timeline-lime .list-timeline-item.show:before,
.list-timeline.list-timeline-lime:before {
  background: #b1dc44;
}

.list-timeline.list-timeline-lime .list-timeline-item:before {
  border-color: #b1dc44;
}

.list-timeline.list-timeline-blue-dark .list-timeline-item.active:before,
.list-timeline.list-timeline-blue-dark .list-timeline-item.show:before,
.list-timeline.list-timeline-blue-dark:before {
  background: #34495e;
}

.list-timeline.list-timeline-blue-dark .list-timeline-item:before {
  border-color: #34495e;
}

.list-timeline.list-timeline-red-dark .list-timeline-item.active:before,
.list-timeline.list-timeline-red-dark .list-timeline-item.show:before,
.list-timeline.list-timeline-red-dark:before {
  background: #a10f2b;
}

.list-timeline.list-timeline-red-dark .list-timeline-item:before {
  border-color: #a10f2b;
}

.list-timeline.list-timeline-brown .list-timeline-item.active:before,
.list-timeline.list-timeline-brown .list-timeline-item.show:before,
.list-timeline.list-timeline-brown:before {
  background: #91633c;
}

.list-timeline.list-timeline-brown .list-timeline-item:before {
  border-color: #91633c;
}

.list-timeline.list-timeline-cyan-dark .list-timeline-item.active:before,
.list-timeline.list-timeline-cyan-dark .list-timeline-item.show:before,
.list-timeline.list-timeline-cyan-dark:before {
  background: #008b8b;
}

.list-timeline.list-timeline-cyan-dark .list-timeline-item:before {
  border-color: #008b8b;
}

.list-timeline.list-timeline-yellow .list-timeline-item.active:before,
.list-timeline.list-timeline-yellow .list-timeline-item.show:before,
.list-timeline.list-timeline-yellow:before {
  background: #d4ac0d;
}

.list-timeline.list-timeline-yellow .list-timeline-item:before {
  border-color: #d4ac0d;
}

.list-timeline.list-timeline-slate .list-timeline-item.active:before,
.list-timeline.list-timeline-slate .list-timeline-item.show:before,
.list-timeline.list-timeline-slate:before {
  background: #5d6d7e;
}

.list-timeline.list-timeline-slate .list-timeline-item:before {
  border-color: #5d6d7e;
}

.list-timeline.list-timeline-olive .list-timeline-item.active:before,
.list-timeline.list-timeline-olive .list-timeline-item.show:before,
.list-timeline.list-timeline-olive:before {
  background: olive;
}

.list-timeline.list-timeline-olive .list-timeline-item:before {
  border-color: olive;
}

.list-timeline.list-timeline-teal .list-timeline-item.active:before,
.list-timeline.list-timeline-teal .list-timeline-item.show:before,
.list-timeline.list-timeline-teal:before {
  background: teal;
}

.list-timeline.list-timeline-teal .list-timeline-item:before {
  border-color: teal;
}

.list-timeline.list-timeline-green-bright .list-timeline-item.active:before,
.list-timeline.list-timeline-green-bright .list-timeline-item.show:before,
.list-timeline.list-timeline-green-bright:before {
  background: #2ecc71;
}

.list-timeline.list-timeline-green-bright .list-timeline-item:before {
  border-color: #2ecc71;
}

.Highlighted {
  background: orange !important;
  box-shadow: orange 0 1px 3px 0 inset !important;
  color: White !important;
  font-weight: bold !important;
}

.render {
  background: #f1f4f6;
  height: 100%;
  width: calc(100vh - 130px);
  margin: auto;
}

.render {
  height: 100%;
}

#calc-table input::-webkit-outer-spin-button,
#calc-table input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
#calc-table input[type=number] {
  -moz-appearance: textfield;
}
#calc-table input[type=number] {
  width: 100px;
}

ul.breadcrumbs {
  margin: 0 !important;
  padding: 16px 10px;
  font-size: 0px;
  line-height: 0px;
  background: #fff;
  border-bottom: 1px solid #ddd;
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: top;
}
ul.breadcrumbs li {
  position: relative;
  margin: 0px 0px;
  padding: 0px;
  list-style: none;
  list-style-image: none;
  display: inline-block;
  *display: inline;
  zoom: 1;
  vertical-align: top;
  border-left: 1px solid #ccc;
  transition: 0.3s ease;
}
ul.breadcrumbs li:hover:before {
  border-left: 10px solid #007bff;
}
ul.breadcrumbs li:hover a {
  color: #fff;
  background: #007bff;
}
ul.breadcrumbs li:before {
  content: "";
  position: absolute;
  right: -9px;
  top: -1px;
  z-index: 11;
  border-left: 10px solid #fff;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  transition: 0.3s ease;
}
ul.breadcrumbs li:after {
  content: "";
  position: absolute;
  right: -10px;
  top: -1px;
  z-index: 10;
  border-left: 10px solid #ccc;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
}
ul.breadcrumbs li.active a {
  color: #fff;
  background: #007bff;
}
ul.breadcrumbs li.first {
  border-left: none;
}
ul.breadcrumbs li.first a {
  font-size: 18px;
  padding-left: 20px;
  border-radius: 5px 0px 0px 5px;
}
ul.breadcrumbs li.last:before {
  display: none;
}
ul.breadcrumbs li.last:after {
  display: none;
}
ul.breadcrumbs li.last a {
  padding-right: 20px;
  border-radius: 0px 40px 40px 0px;
}
ul.breadcrumbs li a {
  display: block;
  font-size: 12px;
  line-height: 40px;
  color: #757575;
  padding: 0px 15px 0px 25px;
  text-decoration: none;
  background: #fff;
  white-space: nowrap;
  overflow: hidden;
  transition: 0.3s ease;
  border: 1px solid #ddd;
}

.app-header {
  transition-duration: 0s;
}

.app-sidebar {
  transition-duration: 0s;
}

.card .card-header:nth-of-type(2) {
  background-color: #eee;
}

body.dark-mode .app-header .app-header__content {
  color: rgb(255, 255, 255);
}
body.dark-mode .app-header .dropdown-menu-lg {
  color: rgba(255, 255, 255, 0.7);
  background-color: #161a1e;
}
body.dark-mode .app-header .dropdown-menu-lg #toggle-theme-btn {
  border-color: rgba(255, 255, 255, 0.7);
}
body.dark-mode .dropdown-menu {
  background-color: #161a1e;
  color: rgba(255, 255, 255, 0.7);
}
body.dark-mode .app-main {
  background-color: #161a1e;
}
body.dark-mode .app-main .app-page-title {
  background-color: #161a1e;
  color: rgb(255, 255, 255);
}
body.dark-mode .app-main .app-page-title .page-title-icon {
  background-color: #1e2026;
}
body.dark-mode .app-main .app-wrapper-footer {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-mode .app-main .app-wrapper-footer .app-footer {
  border-color: #252930;
}
body.dark-mode .app-main .app-wrapper-footer .app-footer__inner {
  background-color: #161a1e;
  border-color: #252930;
  border-left: 0;
}
body.dark-mode .card {
  background-color: #1e2026;
}
body.dark-mode .card .card-header {
  background-color: #1c2329;
  color: rgba(255, 255, 255, 0.7);
}
body.dark-mode .card .card-header .nav .nav-link {
  color: #FFF;
}
body.dark-mode .card .card-header .nav .nav-link :hover {
  color: #7d9eff;
}
body.dark-mode .card .card-header .nav .nav-link.active {
  color: #7d9eff;
}
body.dark-mode .card .card-footer {
  background-color: #1c2329;
  color: rgba(255, 255, 255, 0.7);
}
body.dark-mode .card .card-header:nth-of-type(2) {
  background-color: #2a2d35;
}
body.dark-mode .card .card-body {
  color: rgba(255, 255, 255, 0.7);
}
body.dark-mode .card .widget-chart .widget-chart-content {
  color: rgb(255, 255, 255);
}
body.dark-mode .modal .modal-header {
  background-color: #1c2329;
  color: rgba(255, 255, 255, 0.7);
  border-bottom: #000;
}
body.dark-mode .modal .modal-header span {
  color: #fff;
}
body.dark-mode .modal .modal-body {
  background-color: #1e2026;
  color: rgba(255, 255, 255, 0.7);
}
body.dark-mode .modal .modal-body input[type=file] {
  background-color: #161a1e;
  color: rgba(255, 255, 255, 0.7);
}
body.dark-mode .modal .modal-footer {
  border-top: #000;
  background-color: #1c2329;
}
body.dark-mode .list-group .list-group-item {
  background-color: #1e2026;
}
body.dark-mode .list-group .list-group-item.active {
  background-color: #3f6ad8;
}
body.dark-mode a {
  color: #FFF;
}
body.dark-mode a.btn:hover {
  color: #fff;
}
body.dark-mode a:hover {
  color: #7d9eff;
}
body.dark-mode .table .collapse .card-body {
  background-color: #8b8b8b;
  border-color: #8b8b8b;
}
body.dark-mode .table .collapsing .card-body {
  background-color: #8b8b8b;
  border-color: #8b8b8b;
}
body.dark-mode .table .table {
  box-sizing: border-box;
  background-color: #8b8b8b;
  border-color: #8b8b8b;
}
body.dark-mode .page-item:not(.active) .page-link {
  background-color: #171717;
}
body.dark-mode .page-item .page-link {
  border-color: #404040;
}
body.dark-mode .page-item .page-link {
  border-color: #404040;
}
body.dark-mode .btn-secondary:not(:disabled):not(.disabled).active,
body.dark-mode .btn-secondary:not(:disabled):not(.disabled):active,
body.dark-mode .show > .btn-secondary.dropdown-toggle {
  color: #fff !important;
  background-color: #545b62 !important;
  border-color: #4e555b !important;
}
body.dark-mode .form-control {
  color: #bebebe !important;
  background-color: #2a2d35 !important;
  border: 0;
}
body.dark-mode .select2-selection--single {
  background-color: #2a2d35 !important;
  border: 0;
  border-radius: 4px;
  height: 37px !important;
}
body.dark-mode .select2-selection--single .select2-selection__rendered {
  color: #848e9c;
  line-height: 36px;
  font-size: 14px;
}
body.dark-mode .select2-selection--single .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
}
body.dark-mode .select2-selection--single .select2-selection__placeholder {
  color: #848e9c;
}
body.dark-mode .select2-selection--single .select2-selection__arrow {
  height: 26px;
  position: absolute;
  top: 1px;
  right: 1px;
  width: 20px;
}
body.dark-mode .select2-selection--single .select2-selection__arrow b {
  border-color: #888 transparent transparent transparent;
  border-style: solid;
  border-width: 5px 4px 0 4px;
  height: 0;
  left: 50%;
  margin-left: -4px;
  margin-top: 2px;
  position: absolute;
  top: 50%;
  width: 0;
}
body.dark-mode[dir=rtl] .select2-selection--single .select2-selection__clear {
  float: left;
}
body.dark-mode[dir=rtl] .select2-selection--single .select2-selection__arrow {
  left: 1px;
  right: auto;
}
body.dark-mode.select2-container--disabled {
  background-color: #000 !important;
}
body.dark-mode.select2-container--disabled .select2-selection--single {
  background-color: #000 !important;
  cursor: default;
}
body.dark-mode.select2-container--disabled .select2-selection--single .select2-selection__clear {
  display: none;
}
body.dark-mode.select2-container--open .select2-selection--single .select2-selection__arrow b {
  border-color: transparent transparent #888 transparent;
  border-width: 0 4px 5px 4px;
}
body.dark-mode .select2-selection--multiple {
  background-color: #2a2d35 !important;
  border: 0;
  border-radius: 4px;
  cursor: text;
}
body.dark-mode .select2-selection--multiple .select2-selection__rendered {
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0 5px;
  width: 100%;
}
body.dark-mode .select2-selection--multiple .select2-selection__rendered li {
  list-style: none;
}
body.dark-mode .select2-selection--multiple .select2-selection__placeholder {
  color: #999;
  margin-top: 5px;
  float: left;
}
body.dark-mode .select2-selection--multiple .select2-selection__clear {
  cursor: pointer;
  float: right;
  font-weight: bold;
  margin-top: 5px;
  margin-right: 10px;
}
body.dark-mode .select2-selection--multiple .select2-selection__choice {
  background-color: #000;
  border: 1px solid #aaa;
  border-radius: 4px;
  cursor: default;
  float: left;
  margin-right: 5px;
  margin-top: 5px;
  padding: 0 5px;
}
body.dark-mode .select2-selection--multiple .select2-selection__choice__remove {
  color: #999;
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin-right: 2px;
}
body.dark-mode .select2-selection--multiple .select2-selection__choice__remove:hover {
  color: #333;
}
body.dark-mode[dir=rtl] .select2-selection--multiple .select2-selection__choice,
body.dark-mode[dir=rtl] .select2-selection--multiple .select2-selection__placeholder,
body.dark-mode[dir=rtl] .select2-selection--multiple .select2-search--inline {
  float: right;
}
body.dark-mode[dir=rtl] .select2-selection--multiple .select2-selection__choice {
  margin-left: 5px;
  margin-right: auto;
}
body.dark-mode[dir=rtl] .select2-selection--multiple .select2-selection__choice__remove {
  margin-left: 2px;
  margin-right: auto;
}
body.dark-mode.select2-container--focus .select2-selection--multiple {
  border: solid black 1px;
  outline: 0;
}
body.dark-mode.select2-container--disabled {
  background-color: #000;
}
body.dark-mode.select2-container--disabled .select2-selection--multiple {
  background-color: #000;
  cursor: default;
}
body.dark-mode.select2-container--disabled .select2-selection__choice__remove {
  display: none;
}
body.dark-mode .select2-container--open .select2-dropdown {
  background-color: #000 !important;
  color: #fff;
}
body.dark-mode .select2-container--default .select2-search--dropdown .select2-search__field {
  background-color: #2a2d35;
  color: #fff;
}
body.dark-mode .select2-container--default .select2-results__option--selected,
body.dark-mode .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
  background-color: #f0b90b;
  color: #555;
}
body.dark-mode .select2-container--default .select2-selection--multiple .select2-selection__choice__display {
  padding-left: 20px;
}
body.dark-mode ul.breadcrumbs {
  background: #161a1e;
  border-bottom: 1px solid #252930;
}
body.dark-mode ul.breadcrumbs li {
  border-left: 1px solid #000;
}
body.dark-mode ul.breadcrumbs li:hover:before {
  border-left: 10px solid #1c2329;
}
body.dark-mode ul.breadcrumbs li:hover a {
  color: #aaa;
  background: #1c2329;
}
body.dark-mode ul.breadcrumbs li:before {
  border-left: 10px solid #161a1e;
}
body.dark-mode ul.breadcrumbs li:after {
  border-left: 10px solid #555;
}
body.dark-mode ul.breadcrumbs li.active a {
  color: #555;
  background: #f0b90b;
}
body.dark-mode ul.breadcrumbs li a {
  color: #757575;
  background: #161a1e;
  border: 1px solid #555;
}
body.dark-mode .render,
body.dark-mode #office-preview {
  background: #555;
}
body.dark-mode .border,
body.dark-mode .divider,
body.dark-mode .fc-unthemed .fc-content,
body.dark-mode .fc-unthemed .fc-divider,
body.dark-mode .fc-unthemed .fc-list-heading td,
body.dark-mode .fc-unthemed .fc-list-view,
body.dark-mode .fc-unthemed .fc-popover,
body.dark-mode .fc-unthemed .fc-row,
body.dark-mode .fc-unthemed tbody,
body.dark-mode .fc-unthemed td,
body.dark-mode .fc-unthemed th,
body.dark-mode .fc-unthemed thead {
  border: 1px solid #252930 !important;
}
body.dark-mode .table td,
body.dark-mode .table thead th {
  border-top: 1px solid #252930 !important;
  border-bottom: 0;
}
body.dark-mode .app-sidebar.sidebar-text-light .vertical-nav-menu li a {
  color: #eaecef !important;
}
body.dark-mode .apexcharts-tooltip.apexcharts-theme-light {
  background: #171717;
}
body.dark-mode .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: #555;
}
body.dark-mode pre {
  color: #757575;
}
body.dark-mode .fc-unthemed td.fc-today.fc-sun,
body.dark-mode .fc-unthemed td.fc-today.fc-sat,
body.dark-mode .fc-day.fc-sun,
body.dark-mode .fc-day.fc-past,
body.dark-mode .fc-day.fc-sat {
  background-color: #333 !important;
}
body.dark-mode .filtered_in {
  background: #356747 !important;
}
body.dark-mode .fc-unthemed td.fc-today.fc-day.filtered_in,
body.dark-mode .fc-unthemed td.fc-today.fc-day.fc-sat {
  background-color: #356747 !important;
}
body.dark-mode .daterangepicker {
  background-color: #171717;
  color: #eaecef !important;
}
body.dark-mode .daterangepicker:after {
  border-bottom: 10px solid #171717;
}
body.dark-mode .daterangepicker .calendar-table {
  background-color: #161a1e;
}
body.dark-mode .daterangepicker .ranges li {
  color: #fff;
}
body.dark-mode .daterangepicker .ranges li:hover {
  color: #555;
}
body.dark-mode .daterangepicker td.in-range:not(.end-date) {
  background-color: #171717;
}
body.dark-mode .daterangepicker td.off,
body.dark-mode .daterangepicker td.off.in-range,
body.dark-mode .daterangepicker td.off.start-date,
body.dark-mode .daterangepicker td.off.end-date {
  background-color: #313a3e;
  color: #eaecef !important;
}
body.dark-mode .bsk-btn-default {
  background-color: #1c2329;
}
body.dark-mode .bsk-btn-default:hover {
  background-color: rgb(52, 58, 64);
  color: #fff;
}

.fc-unthemed .fc-row .fc-content-skeleton thead,
.fc-unthemed .fc-row .fc-content-skeleton tbody,
.fc-unthemed .fc-row .fc-content-skeleton td,
.fc-unthemed .fc-row .fc-content-skeleton tr {
  border: 0 !important;
}

.fc-day.fc-sat {
  background-color: #eee !important;
}

.fc-day.fc-sun {
  background-color: #eee !important;
}

.fc-day.fc-past {
  background-color: #eee !important;
}

.fc-row table {
  border-top: 0 hidden transparent;
  border-bottom: initial !important;
}

.fc-unthemed td.fc-today.fc-sun {
  background-color: #eee !important;
}

.fc-unthemed td.fc-today.fc-sat {
  background-color: #eee !important;
}

.fc-row .fc-content-skeleton {
  padding-bottom: 0 !important;
}

.fc-unthemed .fc-row .fc-content-skeleton td {
  border-top: 0 !important;
  border-bottom: 0 !important;
}

.bg-dark,
.dark-mode {
  background-color: #161a1e !important;
}

.apexcharts-svg {
  background: transparent !important;
}

.fc-event-title {
  text-align: center;
  width: 100%;
}

.fc-left {
  margin-bottom: 10px;
}

.lookButton {
  width: 90%;
}

.timesheets-btns-0 {
  display: none !important;
}

.dayButton:before,
.lookButton:before,
.parkingButton:before {
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font: var(--fa-font-solid);
  font-weight: 900;
  font-size: 16px;
  margin-top: 3px;
  margin-right: 5px;
}

.dayButton.btn-secondary:before {
  content: "\f234";
}

.dayButton.btn-success:before {
  content: "\f503";
}

.lookButton:before {
  content: "\f06e";
}

.parkingButton:before {
  content: "\f540";
}

#notification-btn-desktop {
  display: none;
}

.notification-ico-mobile {
  display: inline-block;
}

#notification-btn-mobile {
  display: list-item;
}

#notification-buttons {
  flex-direction: column;
}

#notification-btn-desktop {
  display: inline-block;
}

.notification-ico-mobile {
  display: none;
}

#notification-btn-mobile {
  display: none;
}

#notification-buttons {
  flex-direction: row;
}

@media (max-width: 1799px) {
  .dayButton,
  .parkingButton {
    width: 35px !important;
    height: 35px !important;
    transition: flex 0.2s;
  }
  .timesheets-btns-2 > button {
    font-size: 0 !important;
  }
  .timesheets-btns-2 button > div {
    font-size: initial;
  }
}
@media (min-width: 1800px) {
  .dayButton,
  .parkingButton {
    flex: 1 !important;
    font-size: 14px !important;
  }
  .dayButton div,
  .parkingButton div {
    margin-left: 5px;
  }
}
@media (max-width: 990px) {
  .dropdown-menu-lg {
    top: 155px !important;
  }
  .lookButton {
    font-size: 0 !important;
    margin-right: 0;
    height: 25px !important;
  }
  .dayButton div,
  .parkingButton div {
    font-size: 14px !important;
  }
  .dayButton,
  .parkingButton,
  .timesheets-btns-1 button {
    width: 100% !important;
    border-radius: 0.2rem !important;
    font-size: 0 !important;
  }
  .dayButton:before,
  .parkingButton:before,
  .lookButton:before {
    margin-right: 0 !important;
    font-size: 12px;
  }
}
@media (min-width: 991px) {
  .dropdown-menu-lg {
    top: 40px !important;
  }
  .dayButton,
  .parkingButton {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .dayButton:before,
  .parkingButton:before,
  .lookButton:before {
    margin-top: 0px;
  }
  .timesheets-btns-1 button {
    flex: 1 !important;
    font-size: 14px !important;
  }
  .timesheets-btns-1 button > div {
    margin-left: 5px;
  }
}
@media (max-width: 576px) {
  #notification-btn-desktop {
    display: none;
  }
  .notification-ico-mobile {
    display: inline-block;
  }
  #notification-btn-mobile {
    display: list-item;
  }
  #notification-buttons {
    flex-direction: column;
    align-items: center;
  }
  #notification-buttons button {
    margin-bottom: 1em;
  }
  .dropdown #notification-dropdown {
    transform: translateY(-65%) !important;
  }
}
.filtered_in {
  background-color: #9ade9b !important;
}

.fc-unthemed td.fc-today.filtered_in {
  background-color: #9ade9b !important;
}

.verifgo-container #form-div {
  position: relative;
  top: -100px;
}
.verifgo-container #form-div h1 {
  font-weight: bold;
  font-size: 4.7rem;
  line-height: 1;
}
.verifgo-container #form-div h2 {
  font-size: 1.5rem;
}
.verifgo-container #form-div h3 {
  font-size: 1.3rem;
}
.verifgo-container #form-div input {
  font-size: 1.3em;
  border: 1px solid #dcdcdc;
  border-radius: 5px;
  padding: 5px 15px;
  height: 40px;
  background: none;
  outline: 0;
  -moz-appearance: textfield;
}
.verifgo-container #form-div #verifgo-btn {
  width: 100%;
  white-space: nowrap;
}
.verifgo-container #form-div input::-webkit-outer-spin-button,
.verifgo-container #form-div input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.verifgo-container #result-div {
  position: relative;
  top: -100px;
}
.verifgo-container #result-div p {
  font-size: 1.6em;
}
.verifgo-container #result-div #verify-another-btn {
  padding: 0.6em 3em;
  font-size: 1.1em;
}

@media (max-width: 975px) {
  .verifgo-container #form-div {
    margin-top: 6em;
  }
  .verifgo-container #form-div input {
    font-size: 2em;
    padding: 0.8em 0.9em;
    margin: 0.8em auto;
  }
  .verifgo-container #form-div #verifgo-btn {
    font-size: 1.5em;
    padding: 0.7em 1em;
    width: auto;
  }
}
.notification-dropdown-link {
  color: inherit;
}
.notification-dropdown-link:hover {
  color: inherit;
}

.badge-dot-left {
  right: 35px !important;
}

.scroll-area-comments {
  overflow-x: hidden;
  max-height: 200px;
}
.scroll-area-comments .comments-column:before {
  left: 20px;
}
.scroll-area-comments .comments-column .comments-icon {
  left: 13px;
}
.scroll-area-comments .comments-column .comments-body {
  margin-left: 48px;
}

.is-service-checkbox {
  width: 21px;
  height: 21px;
}

.captcha-img > img {
  height: 60px;
}

body.high-contrast {
  color: #deff00;
  background-color: #000000;
}
body.high-contrast .app-container {
  background-color: #1a1a1a;
}
body.high-contrast .signaller-container {
  background-color: #1a1a1a;
}
body.high-contrast .app-header {
  color: #deff00;
  background-color: #000000;
}
body.high-contrast .alert.alert-danger {
  color: #f33bee;
}
body.high-contrast .alert {
  background-color: #1a1a1a;
  color: #deff00;
}
body.high-contrast .alert .alert-danger {
  color: #f33bee;
}
body.high-contrast .app-main {
  background-color: #161a1e;
}
body.high-contrast .app-main .app-page-title {
  background-color: #161a1e;
  color: rgb(255, 255, 255);
}
body.high-contrast .app-main .app-page-title .page-title-icon {
  background-color: #1e2026;
}
body.high-contrast .card {
  background-color: #000000;
  border: 1px solid #fff;
}
body.high-contrast .card .card-header {
  background-color: #000000;
  color: #deff00;
  border-bottom: 1px solid #fff;
}
body.high-contrast .card .card-header .nav .nav-link {
  color: #FFF;
}
body.high-contrast .card .card-header .nav .nav-link :hover {
  color: #7d9eff;
}
body.high-contrast .card .card-header .nav .nav-link.active {
  color: #7d9eff;
}
body.high-contrast .card .card-footer {
  background-color: #000000;
  color: rgba(255, 255, 255, 0.7);
}
body.high-contrast .card .card-header:nth-of-type(2) {
  background-color: #2a2d35;
}
body.high-contrast .card .card-body {
  color: #deff00;
}
body.high-contrast .card .card-body .card-title {
  color: #deff00;
}
body.high-contrast .card .widget-chart .widget-chart-content {
  color: rgb(255, 255, 255);
}
body.high-contrast input, body.high-contrast textarea {
  background-color: #000000;
  color: #deff00;
  border: 1px solid #fff;
}
body.high-contrast input:hover, body.high-contrast input:focus, body.high-contrast textarea:hover, body.high-contrast textarea:focus {
  background-color: #000000;
  color: #deff00;
  border: 1px solid #fff;
  -webkit-box-shadow: 0 0 5px 2px #fff;
  box-shadow: 0 0 5px 2px #fff;
}
body.high-contrast input input[type=submit], body.high-contrast textarea input[type=submit] {
  border: 2px solid #fff;
}
body.high-contrast input input[type=submit]:hover, body.high-contrast input input[type=submit]:focus, body.high-contrast textarea input[type=submit]:hover, body.high-contrast textarea input[type=submit]:focus {
  background-color: #000000;
  color: #deff00;
  border: 2px solid #fff;
  -webkit-box-shadow: 0 0 5px 2px #fff;
  box-shadow: 0 0 5px 2px #fff;
}
body.high-contrast .text-dark {
  color: #deff00 !important;
}
body.high-contrast .scroll-area-comments {
  background-color: #aaaaaa;
}
body.high-contrast .text-primary, body.high-contrast a, body.high-contrast a:active, body.high-contrast a:visited, body.high-contrast a:hover {
  color: #f33bee !important;
}
body.high-contrast .text-muted {
  color: #deff00 !important;
}
body.high-contrast table td {
  border-top: 1px solid #fff !important;
  border-bottom: 1px solid #fff !important;
}
body.high-contrast button,
body.high-contrast button.btn,
body.high-contrast button.btn-primary,
body.high-contrast .btn-primary,
body.high-contrast .btn {
  background-color: #000000 !important;
  color: #deff00 !important;
  border: 1px solid #fff !important;
}
body.high-contrast button:hover, body.high-contrast button:active,
body.high-contrast button.btn:hover,
body.high-contrast button.btn:active,
body.high-contrast button.btn-primary:hover,
body.high-contrast button.btn-primary:active,
body.high-contrast .btn-primary:hover,
body.high-contrast .btn-primary:active,
body.high-contrast .btn:hover,
body.high-contrast .btn:active {
  -webkit-box-shadow: 0 0 5px 2px #fff;
  box-shadow: 0 0 5px 2px #fff;
}
body.high-contrast .dropdown .btn {
  border: none !important;
}
body.high-contrast .badge-primary {
  background-color: #0048ff !important;
}
body.high-contrast .badge-success {
  background-color: #04952e !important;
}
body.high-contrast .badge-danger {
  background-color: #b9002c !important;
}
body.high-contrast .list-group {
  background-color: #000000;
  border: 1px solid #fff !important;
}
body.high-contrast .list-group .list-group-item {
  background-color: #000000;
  color: #deff00;
  border: 1px solid #fff !important;
}
body.high-contrast .list-group .list-group-item:hover, body.high-contrast .list-group .list-group-item:active {
  -webkit-box-shadow: 0 0 5px 2px #fff;
  box-shadow: 0 0 5px 2px #fff;
}

body.custom-L-font-size {
  font-size: 1.25rem;
}
body.custom-L-font-size input,
body.custom-L-font-size textarea,
body.custom-L-font-size button,
body.custom-L-font-size .card-title,
body.custom-L-font-size .card-header {
  font-size: 1.25rem;
}
body.custom-L-font-size .captcha-img > img {
  height: 90px;
}

body.custom-XL-font-size {
  font-size: 1.5rem;
}
body.custom-XL-font-size input,
body.custom-XL-font-size textarea,
body.custom-XL-font-size button,
body.custom-XL-font-size .card-title,
body.custom-XL-font-size .card-header {
  font-size: 1.5rem;
}
body.custom-XL-font-size .captcha-img > img {
  height: 90px;
}

.dropzone-container {
  display: inline-flex;
  width: 100%;
  min-height: 92px;
  padding: 16px;
  border: 2px dashed rgb(172, 181, 185);
  border-radius: 4px;
  background-color: rgb(244, 245, 246);
  align-items: center;
  justify-content: center;
  text-align: center;
  color: rgb(106, 118, 124);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: all 250ms ease-out 0s;
  letter-spacing: 0.25px;
  cursor: grab;
}
.dropzone-container .dropzone-upload-btn {
  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 20px;
  background: rgb(227, 230, 232);
  color: rgb(35, 39, 41);
  align-items: center;
  border-radius: 4px;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  flex-direction: row;
  flex-shrink: 0;
  font-weight: 600;
  height: 36px;
  justify-content: center;
  margin: 0;
  overflow: hidden;
  padding: 0px 8px;
  position: relative;
  pointer-events: inherit;
  text-overflow: ellipsis;
  user-select: inherit;
  vertical-align: middle;
  white-space: nowrap;
  transition: 0.2s;
}
.dropzone-container .dropzone-upload-btn:hover {
  background: rgb(166, 166, 166);
}

.dropzone-previews {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
}
.dropzone-previews .dropzone-file-preview {
  position: relative;
}
.dropzone-previews .dropzone-file-preview .dropzone-size-info {
  min-width: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  background-color: rgba(63, 63, 63, 0.75);
  padding: 0 5px;
}
.dropzone-previews .dropzone-file-preview .dropzone-name-info {
  min-width: 100px;
  top: 0;
  left: 0;
  right: 0;
  color: #fff;
  background-color: rgba(63, 63, 63, 0.75);
  padding: 0 5px;
}
.dropzone-previews .dropzone-file-preview .dropzone-file-remove {
  left: 0;
  right: 0;
  font-size: 2em;
  color: #fff;
  background-color: rgba(196, 31, 31, 0.75);
  cursor: pointer;
  z-index: 1;
  text-align: center;
  transition: 0.2s;
}
.dropzone-previews .dropzone-file-preview .dropzone-file-remove:hover {
  background-color: rgb(196, 31, 31);
}
.dropzone-previews .dropzone-file-preview .dropzone-file-remove i {
  transition: 0.2s;
}
.dropzone-previews .dropzone-file-preview .dropzone-file-remove:hover i {
  transform: scale(1.2);
}
.dropzone-previews .dz-image-preview .dropzone-size-info {
  position: absolute;
}
.dropzone-previews .dz-image-preview .dropzone-name-info {
  position: absolute;
}
.dropzone-previews .dz-image-preview .dropzone-file-remove {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.dropzone-previews .dz-file-preview {
  width: 150px;
  height: 150px;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
}
.dropzone-previews .internal-note-card {
  background-color: #f3f3c7 !important;
}

#google_translate_element .goog-logo-link {
  display: none;
}

.dynamic-elements:not(.first) {
  background-color: rgba(0, 0, 0, 0.03);
  min-height: 15px;
  padding: 10px;
  border: 1px solid silver;
}

.fab-wrapper {
  margin: 5rem !important;
  margin-right: 1.5rem !important;
}

.modal-backdrop {
  display: none !important;
}