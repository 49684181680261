// Body
$body-bg: #f8fafc;
$body-bg: #1f1f1f;
$dm-card-body-light: #8b8b8b;
$dm-card-body: #1e2026;
$dm-card-header: #1c2329;
$dm-page-title: #161a1e;
$dm-bg: rgb(52, 58, 64);

$hc-text: #deff00;
$hc-back: #000000;
$hc-pink: #f33bee;
$hc-grey: #1a1a1a;
$hc-grey-2: #aaaaaa;
$box-shadow: #fff;

// Typography
$font-family-sans-serif: 'Book Antiqua', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;
$dm-font-color: rgba(255, 255, 255, 0.7);
$dm-font-color-light: rgba(255, 255, 255);
$dm-font-color-dark: rgba(220, 220, 220, 0.7);

$dm-link-color: #FFF;
$dm-link-color-active: #7d9eff;

$L-font-size: 1.25rem;
$XL-font-size: 1.5rem;


// Colors
$blue: #007bff;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
